
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService from "@/core/services/AppService";
import { Actions } from "@/store/enums/StoreEnums";
import { CategoryDefault, categoryModel } from "@/store/model/Category";

export default defineComponent({
  name: "edit-account",
  props: ["id"],
  components: {},
  setup(props) {
    const image_editor = ref();
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataNewsCategory = ref([]);
    let objectDetail = ref<categoryModel>(
      JSON.parse(JSON.stringify(CategoryDefault))
    );

    onMounted(async () => {
      await store.dispatch(Actions.GET_PROJECT_CATEGORY_DETAIL, props.id);
      await store.dispatch(
        Actions.GET_ALL_PROJECT_CATEGORY,
        '?search={"type":"project"}'
      );
      listDataNewsCategory.value = store.getters["listDataProjectCategory"];
      objectDetail.value = await store.getters["getCurrentProjectCategory"];
      setCurrentPageBreadcrumbs("Cập nhật ", ["Danh mục"]);
      MenuComponent.reinitialization();
    });
    const rules = ref({
      name: [
        {
          required: true,
          message: "Tiêu đề không được để trống",
          trigger: "blur",
        },
      ],
    });
    const convertNameToSlug = () => {
      objectDetail.value["slug"] = AppService.convertSlug(
        objectDetail.value["name"]
      );
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          objectDetail.value["active"] =
            objectDetail.value["active"] == "active" ||
            objectDetail.value["active"]
              ? "active"
              : "inactive";
          store
            .dispatch(Actions.UPDATE_PROJECT_CATEGORY, objectDetail.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông tin thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                // Go to page after successfully login
                if (result.isConfirmed) {
                  router.push({ name: "project-category" });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    return {
      rules,
      submit,
      convertNameToSlug,
      loading,
      objectDetail,
      listDataNewsCategory,
      formRef,
      image_editor,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
      }
      if (event.data.action == "image-feature") {
        this.objectDetail["image"] = JSON.parse(event.data.image);
      }
    },
  },
});
